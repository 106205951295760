<template lang="html">
  <div id="header">
    <div class="header-content">

      <!-- Payments -->
      <!-- <div class="box-buy">
        <div class="container d-flex align-items-center oversized-container">
          <div class="col col-lg-3 col-xl col-ml">
            <h5>Compra de forma segura y rápida</h5>
          </div>
          <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/ssl1.png">
            <span>A 246 bits</span>
          </div>
          <div class="col-lg-7 col-logos">
            <span>Paga en línea con tu tarjeta de débito o crédito</span>
            <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-amex icon"></i>
            <img class="img-icon" src="public/images/shared/oxxo.png" alt="Oxxo">
          </div>
        </div>
      </div> -->
      <!--  -->

      <!-- Call us! -->
      <!-- <div class="box-text">
        <p>¡Llámanos al 373 734 0881!</p>
      </div> -->
      <!--  -->

      <!-- Navbar -->
      <b-navbar toggleable="lg" type="light" variant="default">
        <div class="container oversized-container">

          <b-navbar-brand to="/">
            <img class="logo" src="public/images/shared/logo-white.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" class="header-navigation" is-nav>
            <b-navbar-nav class="header-menu">
              <b-nav-item class="simple-item d-lg-none" to="/">{{ $t("shared.header.links.home") }}</b-nav-item>
              <li class="nav-item d-lg-none">
                <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">{{ $t("shared.header.links.products") }}</a>
              </li>
              <!-- <b-nav-item class="simple-item d-lg-none" to="/catalog">Catalog</b-nav-item> -->
              <b-nav-item class="simple-item d-lg-none" to="/about-us">{{ $t("shared.header.links.aboutUs") }}</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/faq">{{ $t("shared.header.links.faqs") }}</b-nav-item>
              <!-- <b-nav-item class="d-lg-none" to="/sucursales">Branch offices</b-nav-item> -->
              <b-nav-item class="d-lg-none" to="/contacto">{{ $t("shared.header.links.contact") }}</b-nav-item>

              <li class="nav-item simple-item d-lg-none">
                <div class="nav-link">
                  <hr class="my-0" />
                </div>
              </li>
              <!-- <b-nav-item class="simple-item cart-item d-lg-none" to="/cart"><i class="far fa-shopping-cart"></i> Mi carrito <i class="num">{{ $root.cartCount }}</i></b-nav-item> -->
              <!-- <b-nav-item class="simple-item d-lg-none" to="/user" v-if="$root.logged"><i class="far fa-user user-icon"></i>&nbsp; {{ $t("shared.header.links.myAccount") }}</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/sign-in" v-if="!$root.logged"><i class="far fa-user user-icon"></i>&nbsp; {{ $t("shared.header.links.signIn") }}</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/sign-up" v-if="!$root.logged"><i class="fas fa-user-plus"></i> {{ $t("shared.header.links.signUp") }}</b-nav-item> -->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form class="search-form order-1 order-lg-0" @submit.prevent="searchForm">
                <b-form-input size="sm" class="mr-sm-2" :placeholder="$t('shared.header.texts.searchProds')" v-model="search.keywords"></b-form-input>
                <b-button class="btn-search" type="submit">{{ $t("shared.header.texts.search") }}</b-button>
              </b-nav-form>

              <li class="nav-item langs-item">
                <div class="nav-link">
                  <a class="btn-lang" v-bind:class="{ 'active' : $i18n.locale == 'en' }" @click="$root.changeLocale('en')">ENG</a>
                  <span> - </span>
                  <a class="btn-lang" v-bind:class="{ 'active' : $i18n.locale == 'es' }" @click="$root.changeLocale('es')">ESP</a>
                </div>
              </li>

              <!-- <b-nav-item-dropdown class="extra-menu order-0 order-lg-1 d-none d-lg-flex" right>
                <template slot="button-content"><span>{{ $t("shared.header.texts.clientAccess") }}</span></template>
                <b-dropdown-item to="/user" v-if="$root.logged">{{ $t("shared.header.links.myAccount") }}</b-dropdown-item>
                <b-dropdown-item to="/sign-up" v-if="!$root.logged">{{ $t("shared.header.links.signUp") }}</b-dropdown-item>
                <b-dropdown-item to="/sign-in" v-if="!$root.logged">{{ $t("shared.header.links.signIn") }}</b-dropdown-item>
              </b-nav-item-dropdown> -->

              <!-- <b-nav-item class="cart-item d-none d-lg-flex" to="/cart"><i class="far fa-shopping-cart cart-icon"></i></b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>

        </div>
      </b-navbar>
      <!--  -->

      <!-- Header bottom -->
      <div class="header-bottom">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <div class="container oversized-container px-1">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mr-auto">
                <b-nav-item to="/novedades">{{ $t("shared.header.links.home") }}</b-nav-item>
                <li class="nav-item b-nav-dropdown dropdown">
                  <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">{{ $t("shared.header.links.products") }}</a>
                </li>
                <!-- <b-nav-item to="/catalog">Catalog</b-nav-item> -->
                <b-nav-item to="/about-us">{{ $t("shared.header.links.aboutUs") }}</b-nav-item>
                <b-nav-item to="/faq">{{ $t("shared.header.links.faqs") }}</b-nav-item>
                <!-- <b-nav-item to="/sucursales">Branch offices</b-nav-item> -->
                <b-nav-item to="/contact">{{ $t("shared.header.links.contact") }}</b-nav-item>

                <!-- <b-nav-item-dropdown right>
                  <template v-slot:button-content>
                    <span>Catálogos</span>
                  </template>
                  <li  v-for="(catalogo, pcatIndex) in catalogos" :key="index">
                    <a class="dropdown-item" target="_blank" :href="catalogo.file">{{ catalogo.name }}</a>
                  </li>
                </b-nav-item-dropdown> -->

                <!-- <b-nav-item to="/distribuidores"><i class="fas fa-tshirt"></i> Distribuidores</b-nav-item> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Products menu -->
      <div class="trans-250 products-menu" v-bind:class="{ active: isPActive }">
        <div class="menu-bg" @click="isPActive = false"></div>

        <div class="tablist" role="tablist">
          <div class="trans-250 box-title" @click="isPActive = false">
            <h4>{{ $t("shared.header.texts.products") }}</h4>
            <br>
            <h5 v-on:click="$router.push('/products');">{{ $t("shared.header.texts.categories") }}</h5>
            <i class="fas fa-chevron-left icon"></i>
          </div>

          <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
            <b-card-header header-tag="header" role="tab">
              <a v-if="category.subcategories" class="btn-toggle" v-b-toggle="'accordion-'+pcatIndex">{{ $i18n.locale == 'es' ? category.name_es  : category.name }}</a>
              <router-link v-else class="btn-toggle" :to="'/category/'+$root._converToURL(category.name, category.id)">{{ $i18n.locale == 'es' ? category.name_es  : category.name }}</router-link>
            </b-card-header>

            <b-collapse v-if="category.subcategories" :id="'accordion-'+pcatIndex" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul class="ul-sub-options">
                  <li v-for="subcategory in category.subcategories">
                    <router-link class="trans-250" :to="'/category/'+$root._converToURL(category.name, category.id)+'/'+$root._converToURL(subcategory.name, subcategory.id)">{{ $i18n.locale == 'es' ? subcategory.name_es  : subcategory.name }}</router-link>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--  -->

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      isPActive: false,
      isDActive: false,

      categories:[],
      catalogos: [],

      search: {
        keywords: null
      }
    }
  },

  methods:{
    searchForm(evt){
        this.$router.push({path: '/search', query: {keywords:this.search.keywords}});
    },

    getCategories: function(){
      axios.get(tools.url('/api/categories')).then((response)=>{
        this.categories = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getCatalogos: function(){
      axios.get(tools.url('/api/catalogos')).then((response)=>{
        this.catalogos = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
      this.isDActive = false;
    }
  },

  beforeMount(){
    this.getCategories();
    //this.getCatalogos();
  }
}
</script>
