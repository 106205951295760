const messages = {
  en: {
    // == Shared ==
    shared: {
      header: {
        links: {
          home: 'Home',
          products: 'Products',
          aboutUs: 'About us',
          faqs: 'F.A.Q.',
          offices: 'Offices',
          contact: 'Contact',
          myAccount: 'My account',
          signIn: 'Sign in',
          signUp: 'Sign up',
        },

        texts: {
          searchProds: 'Search products',
          search: 'SEARCH',
          clientAccess: 'CLIENT ACCESS',
          products: 'PRODUCTS',
          categories: 'Categories',
          help: 'Help',

        }
      },

      footer: {
        texts: {
          subscribeN: 'Subscribe to our Newsletter',
          email: 'Your email',
          subscribe: 'SUBSCRIBE',
          location: 'Location',
          address: `8160 N Interstate 45,<br />Service RD, Palmer, TX 75152<br />`,
          seeMap: 'See map',
          faqs: 'FAQs',
          help: 'Help'
        },

        links: {
          order: 'How can I place my order?',
          payment: 'Payment choices',
          shipping: 'How much does shipping cost?',
          buy: 'How to buy?',
          terms: 'Terms & Conditions',
          notice: 'Privacy notice',
        }
      }
    },
    // == ==

    // Pages ==
    homePage: {
      titles: {
        brands: 'Search by Brands',
        products: 'Featured products',
        categories: 'CATEGORIES',
        parts: 'Most wanted parts',
        aboutUs: 'A bit about us',
      },

      texts: {
        noStock: 'Out of stock',
        explore: 'Explore',
        showMore: 'SHOW MORE',
        about5PG: '5pg Truck Parts is a newly established company that has the support of its sister Companies Cromadora Hermanos Pulido and Pulido Truck and Parts which have more than 30 years in the market to meet the most demanding customers.',
      }
    },

    productsPage: {
      texts: {
        categories: 'Categories',
        product: 'Product',
        products1: 'Products',
        products2: 'PRODUCTS',
        brands: 'Brands',
        showFilters: 'Show filters',
        filters: 'Search Filters',
        brand: 'Brand',
        model: 'Model',
        type: 'Type',
        size: 'Size',
        color: 'Color',
        cuello: 'Cuello',
        termination: 'Termination',
        noStock: 'Out of stock',
        explore: 'EXPLORE',
        noResults: 'No results found',

        sku: 'SKU',
        description: 'Description',
        category: 'Category',
        subcategory: 'Subcategory',
        quantity: 'Quantity',
        askInfo: 'Ask for information',
        share: 'Share',
        relProducts: 'Related products',
      }
    },

    aboutUsPage: {
      title: 'A bit about us',
      video: 'video',
      text: ' 5pg Truck Parts is a newly established company that has the support of its sister Companies Cromadora Hermanos Pulido and Pulido Truck and Parts which have more than 30 years in the market to meet the most demanding customers.'
    },

    faqsPage: {
      title: 'F.A.Q.',
      q1: 'How can I place my order?',
      a1: `To order a product, this should be available on the website within the range of products Available online. <br />
        Follow these easy steps <a target="_blank" href="/#/how-to-buy">here</a>`,
      q2: 'Payment choices',
      a2: 'We currently accept Visa, MasterCard, American Express and Discover. We are also able to accept money orders, PayPal, electronic checks and personal checks. To make money orders or personal checks please email us with details of your order and personal details (name and address). We will send the invoice by email and procedures.',
      q3: 'How do I know my order is secure?',
      a3: 'Protecting your order information is important to us. We highly secure servers to protect your information online.',
      q4: 'How will I be charged?',
      a4: 'Company staff will contact you first to confirm your order placed online, it shall inform the delivery time and product availability, while confirming its form and payment date. All prices are in US dollars (USD).',
      q5: 'How much are the shipping and delivery times are?',
      a5: 'All orders will ship from Palmer Texas or directly from the manufacturer by UPS or UPS Priority email. When salesperson confirm with you your order made, they will inform the shipping and delivery time.',
      q6: 'Is there any charge of customs for international orders?',
      a6: 'Orders shipped outside the US through customs and may be subject to import duties, taxes and custom processing fees that the customer is responsible. Contact your local customs office for information on what charges will apply to international orders.',
      q7: 'International orders and billing verification:',
      a7: `All international orders, or orders using international credit cards must be accompanied with a fax that includes a photo ID, the credit card number and expiration date, and the legend: "I (your name), authorize the following purchase (I add what you bought) with the amount of (write total) " with your signature.<br />
        All verifications are safe for you, and we appreciate your patience in processing information.`,
      q8: 'How i can change / cancel my order?',
      a8: `Once orders are placed on our website, for changes or cancellations, please contact us as soon as possible so as not to start the ordering process.<br />
        Attention: responsible for orders already shipped.<br />
        See our refund policy for details.`,
      q9: 'Which is your refund / interchange policy?',
      a9: `Please, consider the fact that, if we made a mistake on your order, we will take full responsibility to correct it. Please contact us if an event of this type occurs. All products sold by 5 PG TRUCK PARTS are high quality, brand, and come with factory warranties. Please verify all items upon receipt.<br />
        If the items are damaged please report it to us immediately, and we will take full responsibility for its replacement. We ask you to send back the items to us, please note that you have 30 days to do, and that is only valid if the item is new and not used. 5 PG TRUCK PARTS reserves the right to charge a restocking fee of 20% to any change, at our discretion.`,
      q10: 'How i can contact with you?',
      a10: `Please, see the Section Contact Us on site for more information.<br />
        <a target="_blank" href="/#/contact">Go to section</a>`,
      q11: 'Do all my pieces are going to get together?',
      a11: 'Although most of our ship orders from our central warehouse in Palmer Texas, will be cases in which orders are divided into several shipments. But do not worry! It will provide you with additional tracking numbers if an order is broken or is sent from two locations.',
      q12: 'My order was damaged in shipping, what do I do?',
      a12: `If you are present during the delivery and the package is damaged, ask the delivery driver to wait while you check the contents of the package. If you find anything damaged, refuse the package by the reasoning of damage during transport. This means that you are sending back to us. Then call the following Mobile Phones: Mobile: 214-930-6221 Office: 972-449-3504 / 972-449-3538 to inform us of the damage, and inform you of any measure.<br />
        <br />
        If it is too late to reject the package, take many informative photos of the damage to (A) the product and the condition of the containers they feel are necessary (2-3 operate normally). Save the packaging may be required for the investigation process conducted by the delivery service. Then call the following Mobile Phones: Mobile: 214-930-6221 Office: 972-449-3504 / 972-449-3538 and we can start your claim.`,
      q13: 'Do you ship to Canada?',
      a13: `Yes! We ship both UPS and USPS to Canada. If you feel that the product you buy is not giving your zip code in Canada an official shipping quote please contact one of our team members by calling 352-789-6701.
        <br />
        In areas certain our free phone cannot function. Canadian Customs requires recipients to pay duties and taxes in every shipment entering Canada. These charges may include POST, GST, HST, duties and other taxes of goods. A brokerage fee also may be charged to process shipments and submit the customs documentation required for Customs Canada on behalf of the importer.`,
      q14: 'How do I hasten an order?',
      a14: `At this time, the only way you will be able to run an order is a team member calling our toll-free number during working hours contact. The team member needs your order information and checks whether the order has left our warehouse.<br />
        If your order already has a tracking number associated with it we will no longer be able to speed it.<br />
        Additional charges may apply to get the order.`,
      q15: 'I ordered a weekend, when I can expect my order to ship?',
      a15: 'Your order will be processed Monday morning and will contact you our sales staff to confirm your order placed, inform the delivery time and payment. This also applies to holidays, and orders are processed the next business day (s) after the end of the non-working dates.',
      q16: 'I would ask for an article, but I can not find it on the site.',
      a16: `While we are many brands and manufactures many articles truck accessories, we can not list each individual item. Please send an email to our sales staff, he will check availability and a quote on any product.<br />
        <br />
        Please see our contact section for CONTACT Us for more information. Note that you can offer package deals for large orders.`,
    },

    contactPage: {
      title: 'CONTACT',
      text1: 'Please, send us the form below and we will as soon as possible, for us is very important to answer all your questions and comments.',
      location: 'Location',
      phone: 'Cellphone',
      office: 'Office phone',

      form: {
        labName: 'Name:',
        plName: 'Full name',
        labState: 'State',
        labEmail: 'Email',
        plEmail: 'name@email.com',
        labPhone: 'Phone',
        labMessage: 'Message',
        plMessage: 'Your message here',
        send: 'SEND',
      }
    },

    privacyPage: {
      title: 'Privacy notice',
      onlyEnglish: 'Only in English',
    },

    termsPage: {
      title: 'Terms & Conditions / Ordering Guidelines',
      onlyEnglish: 'Only in English',
    },

    accessPage: {
      signIn: 'Sign in',
      signUp: 'Sign up',
      name: 'Name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      passwordCon: 'Password confirmation',
      forgotPassword: 'Forgot your password?',
      accept: 'I accept the ',
      terms: 'Terms & Conditions',
      haveAccount: 'Already have an account?',
    },

    howtoBuyPage: {
      title: 'How to buy?',
      step1Title: 'Log in or create an account',
      step1Descr: 'You can log in Login area located at the top of the page. If you do not have an account please register by clicking register the icon  register that is next to the Login. By registering you will receive an email to verify your account, your account this fact must be activated by an administrator of the page in a maximum period of 48 hrs.',
      step2Title: 'Browse products',
      step2Descr: 'Browse the online offer products and click to see the details of the product, if you are not sure about the characteristics or have questions about the product, please contact us at the following Mobile phone: 214-930-6221 Office: 972-449-3504 / 972-449-3538 for assistance.',
      step3Title: 'Checkout',
      step3Descr: 'To order, you need to click on the top button of "Truck-Total" or directly on the Pop-up generated by adding a product to cart, by clicking on the "Cart" button. Once inside your Shopping Orders must corroborate if your order is correct, confirmed this you may proceed to ordering.',
      step4Title: 'Confirmation mail',
      step4Descr: 'Once you submit the application form, you will be received a confirmation mail with a folio number for follow-up, if not, make sure the email address you provided us in your registration to be valid.',
      step5Title: 'Verification Order',
      step5Descr: 'Our sales staff will contact you to verify the contact information provided, will be notified time of delivery and existence of all products ordered further confirm their form and date of payment.',
    },
    // == ==
  },

  es: {
    // == Shared ==
    shared: {
      header: {
        links: {
          home: 'Inicio',
          products: 'Productos',
          aboutUs: 'Acerca de',
          faqs: 'Preguntas frecuentes',
          offices: 'Oficinas',
          contact: 'Contacto',
          myAccount: 'Mi cuenta',
          signIn: 'Iniciar sesión',
          signUp: 'Registrarse',
        },

        texts: {
          searchProds: 'Buscar productos',
          search: 'BUSCAR',
          clientAccess: 'ACCESO CLIENTES',
          products: 'PRODUCTOS',
          categories: 'Categorías',
          help: 'Ayuda',
        }
      },

      footer: {
        texts: {
          subscribeN: 'Suscríbase al boletín',
          email: 'Su correo electrónico',
          subscribe: 'SUSCRIBIRSE',
          location: 'Ubicación',
          address: `8160 N Interstate 45,<br />Service RD, Palmer, TX 75152<br />`,
          seeMap: 'Ver mapa',
          faqs: 'Preguntas',
          help: 'Ayuda'
        },

        links: {
          order: '¿Cómo puedo hacer mi pedido?',
          payment: 'Opciones de pago',
          shipping: '¿Cuánto cuesta el envío?',
          buy: '¿Cómo comprar?',
          terms: 'Términos y condiciones',
          notice: 'Aviso de Privacidad',
        }
      }
    },
    // == ==

    // Pages ==
    homePage: {
      titles: {
        brands: 'Buscar por marcas',
        products: 'Productos Destacados',
        categories: 'CATEGORÍAS',
        parts: 'Piezas más buscadas',
        aboutUs: 'Un poco sobre nosotros',
      },

      texts: {
        noStock: 'Agotado',
        explore: 'Explorar',
        showMore: 'VER MAS',
        about5PG: '5pg Truck Parts es una empresa de reciente creación que cuenta con el apoyo de sus Empresas hermanas Cromadora Hermanos Pulido y Pulido Truck and Parts las cuales cuentan con más de 30 años en el mercado para atender a los clientes más exigentes.',
      }
    },

    productsPage: {
      texts: {
        categories: 'Categorías',
        product: 'Producto',
        products1: 'Productos',
        products2: 'PRODUCTOS',
        brands: 'Marcas',
        showFilters: 'Mostrar filtros',
        filters: 'Buscar por filtros',
        brand: 'Marca',
        model: 'Modelo',
        type: 'Tipo',
        size: 'Tamaño',
        color: 'Color',
        cuello: 'Cuello',
        termination: 'Terminación',
        noStock: 'Agotado',
        explore: 'EXPLORAR',
        noResults: 'No se han encontrado resultados',

        sku: 'SKU',
        description: 'Descripción',
        category: 'Categoría',
        subcategory: 'Subcategoría',
        quantity: 'Cantidad',
        askInfo: 'Solicite información',
        share: 'Compartir',
        relProducts: 'Productos relacionados',
      }
    },

    aboutUsPage: {
      title: 'Un poco sobre nosotros',
      video: 'Video',
      text: '5pg Truck Parts es una empresa de reciente creación que cuenta con el apoyo de sus Empresas hermanas Cromadora Hermanos Pulido y Pulido Truck and Parts las cuales cuentan con más de 30 años en el mercado para atender a los clientes más exigentes.'
    },

    faqsPage: {
      title: 'Preguntas frecuentes',
      q1: '¿Cómo puedo hacer mi pedido?',
      a1: `Para solicitar un producto, este debe estar disponible en el sitio web dentro de la gama de productos disponibles en línea. <br />
         Siga estos sencillos pasos <a target="_blank" href="/#/how-to-buy">aquí</a>`,
      q2: 'Formas de pago',
      a2: 'Actualmente aceptamos Visa, MasterCard, American Express y Discover. También podemos aceptar giros postales, PayPal, cheques electrónicos y cheques personales. Para hacer giros postales o cheques personales, envíenos un correo electrónico con los detalles de su pedido y datos personales (nombre y dirección). Le enviaremos la factura por correo electrónico y trámites.',
      q3: '¿Cómo sé que es seguro hacer mi pedido?',
      a3: 'Proteger la información de su pedido es importante para nosotros. Tenemos servidores altamente seguros para proteger su información en línea.',
      q4: '¿Cómo se me cobrará?',
      a4: 'El personal de la empresa se pondrá en contacto con usted primero para confirmar su pedido realizado en línea, le informará el tiempo de entrega y la disponibilidad del producto, mientras confirma su forma y fecha de pago. Todos los precios están expresados en dólares estadounidenses (USD).',
      q5: '¿Cuáles son los tiempos de envío y entrega?',
      a5: 'Todos los pedidos se enviarán desde Palmer Texas o directamente desde el fabricante por correo electrónico de UPS o UPS Priority. Cuando el vendedor confirme con usted su pedido realizado, le informará el tiempo de envío y entrega.',
      q6: '¿Hay algún cargo de aduana para pedidos internacionales?',
      a6: 'Los pedidos enviados fuera de los EE. UU. a través de la aduana pueden estar sujetos a derechos de importación, impuestos y tarifas de procesamiento personalizado de los que el cliente es responsable. Póngase en contacto con su oficina de aduanas local para obtener información sobre los cargos que se aplicarán a los pedidos internacionales.',
      q7: 'Pedidos internacionales y verificación de facturación:',
      a7: `Todos los pedidos internacionales, o pedidos con tarjetas de crédito internacionales, deben ir acompañados de un fax que incluya una identificación con foto, el número de tarjeta de crédito y la fecha de vencimiento, y la leyenda: "Yo (su nombre), autorizo la siguiente compra (Agrego lo que comprado) con la cantidad de (escriba el total) " con su firma.<br />
         Todas las verificaciones son seguras para usted y apreciamos su paciencia en el procesamiento de la información.`,
      q8: '¿Cómo puedo cambiar/cancelar mi pedido?',
      a8: `Una vez realizados los pedidos en nuestra web, para cambios o cancelaciones, por favor contacte con nosotros lo antes posible para no iniciar el proceso de pedido.<br />
         Atención: responsable de pedidos ya enviados.<br />
         Consulte nuestra política de reembolso para obtener más información.`,
      q9: '¿Cuál es su política de reembolso/intercambio?',
      a9: `Por favor, tenga en cuenta el hecho de que, si cometimos un error en su pedido, asumiremos toda la responsabilidad para corregirlo. Por favor contáctenos si ocurre un evento de este tipo. Todos los productos vendidos por 5 PG TRUCK PARTS son de alta calidad, marca y vienen con garantías de fábrica. Verifique todos los artículos al recibirlos.<br />
         Si los artículos están dañados, infórmenos de inmediato y asumiremos toda la responsabilidad de su reemplazo. Le pedimos que nos devuelva los artículos, tenga en cuenta que tiene 30 días para hacerlo, y eso solo es válido si el artículo es nuevo y no se usa. 5 PG TRUCK PARTS se reserva el derecho de cobrar una tarifa de reposición del 20% a cualquier cambio, a nuestra discreción.`,
      q10: '¿Cómo puedo ponerme en contacto con usted?',
      a10: `Consulte la sección Contáctenos en el sitio para obtener más información.<br />
         <a target="_blank" href="/#/contact">Ir a la sección</a>`,
      q11: '¿Todas mis piezas van a juntas?',
      a11: 'Aunque la mayoría de nuestros pedidos se envían desde nuestro almacén central en Palmer Texas, serán casos en los que los pedidos se dividen en varios envíos. ¡Pero no se preocupe! Se le proporcionará números de seguimiento adicionales si un pedido se rompe o se envía desde dos ubicaciones.',
      q12: 'Mi pedido se dañó en el envío, ¿qué hago?',
      a12: `Si está presente durante la entrega y el paquete está dañado, pídale al repartidor que espere mientras revisa el contenido del paquete. Si encuentra algo dañado, rechace el paquete por el motivo de daño durante el transporte. Esto significa que nos está enviando de vuelta. Entonces llame a los siguientes Teléfonos Móviles: Móvil: 214-930-6221 Oficina: 972-449-3504 / 972-449-3538 para informarnos del daño, e informarle de cualquier medida.<br />
         <br />
         Si es demasiado tarde para rechazar el paquete, tome muchas fotos informativas del daño a (A) el producto y la condición de los contenedores que consideren necesarios (2-3 funcionan normalmente). Es posible que se requiera guardar el embalaje para el proceso de investigación realizado por el servicio de entrega. Entonces llama a los siguientes Teléfonos Móviles: Móvil: 214-930-6221 Oficina: 972-449-3504 / 972-449-3538 y podemos iniciar tu reclamo.`,
      q13: '¿Hacen envíos a Canadá?',
      a13: `¡Sí! Enviamos tanto UPS como USPS a Canadá. Si cree que el producto que compra no le da a su código postal en Canadá una cotización de envío oficial, comuníquese con uno de los miembros de nuestro equipo llamando al 352-789-6701.
         <br />
         En ciertas áreas, nuestro teléfono gratuito no puede funcionar. La Aduana canadiense requiere que los destinatarios paguen aranceles e impuestos en cada envío que ingrese a Canadá. Estos cargos pueden incluir POST, GST, HST, aranceles y otros impuestos de bienes. También se puede cobrar una tarifa de corretaje para procesar los envíos y presentar la documentación aduanera requerida por Customs Canada en nombre del importador.`,
      q14: '¿Cómo acelero un pedido?',
      a14: `En este momento, la única forma en que podrá ejecutar un pedido es que un miembro del equipo llame a nuestro número gratuito durante el horario laboral. El miembro del equipo necesita la información de su pedido y verifica si el pedido ha salido de nuestro almacén.<br />
         Si su pedido ya tiene un número de seguimiento asociado, ya no podremos acelerarlo.<br />
         Se pueden aplicar cargos adicionales para obtener el pedido.`,
      q15: 'Pedí un fin de semana, ¿cuándo puedo esperar que se envíe mi pedido?',
      a15: 'Su pedido será procesado el lunes por la mañana y nuestro personal de ventas se comunicará con usted para confirmar su pedido realizado, informarle el tiempo de entrega y el pago. Esto también se aplica a los días festivos, y los pedidos se procesan el (los) siguiente (s) día (s) hábil (es) después del final de las fechas no hábiles.',
      q16: 'Pediría un artículo, pero no puedo encontrarlo en el sitio.',
      a16: `Si bien somos muchas marcas y fabricamos muchos artículos de accesorios para camiones, no podemos enumerar cada artículo individual. Envíe un correo electrónico a nuestro personal de ventas, él verificará la disponibilidad y una cotización de cualquier producto.<br />
         <br />
         Consulte nuestra sección de contacto para CONTÁCTENOS para obtener más información. Tenga en cuenta que puede ofrecer ofertas de paquetes para pedidos grandes.`,
    },

    contactPage: {
      title: 'CONTACTO',
      text1: 'Por favor, envíenos el siguiente formulario y lo haremos lo antes posible, para nosotros es muy importante responder a todas sus preguntas y comentarios.',
      location: 'Ubicación',
      phone: 'Celular',
      office: 'Tel. de oficina',

      form: {
        labName: 'Nombre',
        plName: 'Nombre completo',
        labState: 'Estado',
        labEmail: 'Correo electrónico',
        plEmail: 'nombre@correo.com',
        labPhone: 'Teléfono',
        labMessage: 'Mensaje',
        plMessage: 'Su mensaje aquí',
        send: 'ENVIAR',
      }
    },

    privacyPage: {
      title: 'Aviso de Privacidad',
      onlyEnglish: 'Solo en ingles',
    },

    termsPage: {
      title: 'Términos y condiciones / Pautas para pedidos',
      onlyEnglish: 'Solo en ingles',
    },

    accessPage: {
      signIn: 'Iniciar sesión',
      signUp: 'Registrarse',
      name: 'Nombre',
      lastName: 'Apellidos',
      email: 'Correo electrónico',
      password: 'Contraseña',
      passwordCon: 'Confirmar contraseña',
      forgotPassword: '¿Olvidó su contraseña?',
      accept: 'Acepto los ',
      terms: 'Terminos y condiciones',
      haveAccount: '¿Ya tiene una cuenta?',
    },

    howtoBuyPage: {
      title: '¿Cómo comprar?',
      step1Title: 'Ingresar o Crear una cuenta',
      step1Descr: 'Puede iniciar sesión en el área de inicio de sesión ubicada en la parte superior de la página. Si no tiene una cuenta, regístrese haciendo clic en el icono de registro que se encuentra junto al inicio de sesión. Al registrarte recibirás un correo electrónico para verificar tu cuenta, tu cuenta este hecho debe ser activada por un administrador de la página en un plazo máximo de 48 hrs.',
      step2Title: 'Explorar productos',
      step2Descr: 'Explore los productos de la oferta en línea y haga clic para ver los detalles del producto, si no está seguro de las características o tiene preguntas sobre el producto, contáctenos al siguiente Teléfono móvil: 214-930-6221 Oficina: 972-449- 3504 / 972-449-3538 para asistencia.',
      step3Title: 'Verificar',
      step3Descr: 'Para realizar un pedido, debe hacer clic en el botón superior de "Truck-Total" o directamente en el Pop-up generado al agregar un producto al carrito, haciendo clic en el botón "Carrito". Una vez dentro de sus Pedidos de Compra deberá corroborar si su pedido es correcto, confirmado esto podrá proceder a realizar el pedido.',
      step4Title: 'Correo de confirmación',
      step4Descr: 'Una vez que envíe el formulario de solicitud, recibirá un correo de confirmación con un número de folio para darle seguimiento, de no ser así, asegúrese de que la dirección de correo electrónico que nos proporcionó en su registro sea válida.',
      step5Title: 'Orden de verificación',
      step5Descr: 'Nuestro personal de ventas se comunicará con usted para verificar los datos de contacto proporcionados, se le notificará la hora de entrega y existencia de todos los productos solicitados, además confirmará su forma y fecha de pago.',
    },
    // == ==
  }
}

export default messages;
