<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="login()">

            <h1 class="mb-3">{{ $t('accessPage.signIn') }}</h1>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required :placeholder="$t('accessPage.email')"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.password" required :placeholder="$t('accessPage.password')"></b-form-input>
            </b-form-group>

            <p class="mb-3">
              <router-link to="/reset-password">{{ $t('accessPage.forgotPassword') }}</router-link>
            </p>

            <b-form-group>
              <b-button type="submit" class="btn-blue" variant="primary">{{ $t('accessPage.signIn') }}</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: '',
        password: ''
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    login(){
        axios.get(tools.url('/sanctum/csrf-cookie')).then(() => {
            axios.post(tools.url("/sign-in"), this.form).then((response)=>{
                this.$parent.user = response.data;
                this.$parent.logged = true;
                this.$router.push('/user');

            }).catch((error)=>{
                //console.log(error.response.data.error);
                this.handleErrors(error);
            });
        });
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="¡¡¡Error!!!";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  mounted(){
    if(this.$root.logged){
        this.$router.push("/user");
    }
  }
}
</script>
