<template lang="html">
  <div id="howtobuy-page">

    <section class="container oversized-container steps-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title"><span>{{ $t('howtoBuyPage.title') }}</span></h2>
        </div>
      </div>

      <div class="row align-items-stretch">
        <div class="col-lg-6 col-step">
          <div class="align-items-center box">
            <div class="col col-icon">
              <i class="fal fa-user icon"></i>
            </div>

            <div class="col col-text">
              <h2 class="num">1</h2>

              <h5 class="title">{{ $t('howtoBuyPage.step1Title') }}</h5>
              <p>
                {{ $t('howtoBuyPage.step1Descr') }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-step">
          <div class="align-items-center box">
            <div class="col col-icon">
              <i class="fal fa-globe icon"></i>
            </div>

            <div class="col col-text">
              <h2 class="num">2</h2>

              <h5 class="title">{{ $t('howtoBuyPage.step2Title') }}</h5>
              <p>
                {{ $t('howtoBuyPage.step2Descr') }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-step">
          <div class="align-items-center box">
            <div class="col col-icon">
              <i class="fal fa-shopping-basket icon"></i>
            </div>

            <div class="col col-text">
              <h2 class="num">3</h2>

              <h5 class="title">{{ $t('howtoBuyPage.step3Title') }}</h5>
              <p>
                {{ $t('howtoBuyPage.step3Descr') }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-step">
          <div class="align-items-center box">
            <div class="col col-icon">
              <i class="fal fa-envelope icon"></i>
            </div>

            <div class="col col-text">
              <h2 class="num">4</h2>

              <h5 class="title">{{ $t('howtoBuyPage.step4Title') }}</h5>
              <p>
                {{ $t('howtoBuyPage.step4Descr') }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-step">
          <div class="align-items-center box">
            <div class="col col-icon">
              <i class="fal fa-clipboard-list-check icon"></i>
            </div>

            <div class="col col-text">
              <h2 class="num">5</h2>

              <h5 class="title">{{ $t('howtoBuyPage.step5Title') }}</h5>
              <p>
                {{ $t('howtoBuyPage.step5Descr') }}
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
