<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>{{ $t('productsPage.texts.categories') }}</span>
        <span class="line">/</span>
        <span>{{ category.name }}</span>
        <span class="line" v-if="subcategory != null">/</span>
        <span v-if="subcategory != null">{{ $i18n.locale == 'es' ? subcategory.name_es  : subcategory.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ category.name }}</h2>
          <h2 class="title" v-if="subcategory != null">{{ $i18n.locale == 'es' ? subcategory.name_es  : subcategory.name }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>{{ $t('productsPage.texts.showFilters') }}</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>{{ $t('productsPage.texts.filters') }}</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container" @submit="onSubmitFilters">

            <!-- Categorías y subcategorías -->
            <div class="box-filters mb-0">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.categories') }}</a>
            </div>

            <div class="box-f-categories">
              <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
                <b-card-header header-tag="header" role="tab">
                  <div class="btn-toggle" v-b-toggle="'accordion-'+pcatIndex">
                    <!-- <router-link :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link> -->
                    <a>{{ $i18n.locale == 'es' ? category.name_es  : category.name }}</a>
                    <!-- <span class="fake-toggle" v-if="category.subcategories"></span> -->
                  </div>
                </b-card-header>

                <b-collapse v-if="category.subcategories" class="box-subs" :id="'accordion-'+pcatIndex" :visible="(category_id == category.id ? true : false)" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <ul class="ul-sub-options">
                      <li v-for="subcategory in category.subcategories">
                        <router-link class="trans-250" v-bind:class="{ 'active' : subcategory_id == subcategory.id }" :to="'/category/'+$root._converToURL(category.name, category.id)+'/'+$root._converToURL(subcategory.name, subcategory.id)">{{ $i18n.locale == 'es' ? subcategory.name_es  : subcategory.name }}</router-link>
                      </li>
                    </ul>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

            <!-- Marca -->
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.brand') }}</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="filter_brand"
                  :aria-describedby="ariaDescribedby"
                  name="filter-1">
                  <b-form-radio :value="i.id" v-for="(i, fbrInx) in brandsOptions" :key="'fbrInx-'+fbrInx">{{ i.name }} {{ i.productCount ? '('+i.productCount+')' : '' }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <!--  -->

            <!-- Marca -->
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.model') }}</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filter_model"
                  :aria-describedby="ariaDescribedby"
                  name="filter-2">
                  <b-form-checkbox :value="m.id" v-for="(m, mInx) in modelsOptions" :key="'model-'+mInx">{{ m.name }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <!--  -->

            <!-- extras -->
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.type') }}</a>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="filters.tipo" :aria-describedby="ariaDescribedby" name="extra-tipo">
                  <b-form-radio :value="value" v-for="(value, index) in tipo" :key="'tipo-'+index">{{ value }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.size') }}</a>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="filters.medida" :aria-describedby="ariaDescribedby" name="extra-medida">
                  <b-form-radio :value="value" v-for="(value, index) in medida" :key="'medida-'+index">{{ value }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.color') }}</a>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="filters.color" :aria-describedby="ariaDescribedby" name="extra-color">
                  <b-form-radio :value="value" v-for="(value, index) in color" :key="'color-'+index">{{ value }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

             <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.cuello') }}</a>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="filters.cuello" :aria-describedby="ariaDescribedby" name="extra-cuello">
                  <b-form-radio :value="value" v-for="(value, index) in cuello" :key="'cuello-'+index">{{ value }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.termination') }}</a>
              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="filters.terminacion" :aria-describedby="ariaDescribedby" name="extra-terminacion">
                  <b-form-radio :value="value" v-for="(value, index) in terminacion" :key="'terminacion-'+index">{{ value }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e">
            <div class="col-sm-6 col-md-4 col-lg-3 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/product/'+ $root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="out-of-stock" v-if="product.stock < 1">{{ $t('productsPage.texts.noStock') }}</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                </div>
                <p class="p-button">
                  <a class="btn-more">{{ $t('productsPage.texts.explore') }}</a>
                </p>
              </router-link>
            </div>

            <div class="col-12 text-center">
              <h5 v-if="!products.length">{{ $t('productsPage.texts.noResults') }}</h5>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      filters: {
        tipo: null,
        medida: null,
        color: null,
        cuello: null,
        terminacion: null,
      },

      categories: [],

      category: [],
      subcategory:null,
      category_id:null,
      subcategory_id:null,
      products: [],
      productsAll:[],

      filter_brand: null,
      filter_model: [],
      brandsOptions:[],
      modelsOptions:[],

      tipo: [],
      medida: [],
      color: [],
      cuello: [],
      terminacion: [],
    }
  },

  watch: {
    "$route.params.category" : function(v){
        this.filter_brand = null;
        this.filter_model = [];
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);
        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    "$route.params.subcategory" : function(v){
        this.filter_brand = null;
        this.filter_model = [];
        this.category_id = this.$root._getURLID(this.$route.params.category);
        this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
        this.getProductsCategory(this.category_id, this.subcategory_id);
    },

    'filter_brand': function(val){
        if(this.subcategory_id){
            this.getProductsCategory(this.category_id, this.subcategory_id);
        }else{
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filter_model': function(val){
        if(this.subcategory_id){
            this.getProductsCategory(this.category_id, this.subcategory_id);
        }else{
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filters.tipo': function(val){
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);

        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filters.medida': function(val){
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);

        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filters.color': function(val){
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);

        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filters.cuello': function(val){
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);

        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    'filters.terminacion': function(val){
        if(this.$route.params.subcategory){
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
            this.getProductsCategory(this.category_id, this.subcategory_id);

        }else{
            this.category_id = this.$root._getURLID(this.$route.params.category);
            this.getProductsCategory(this.category_id, null);
        }
    },

    '$i18n.locale': function(newVal, oldVal) {
      this.textFilter();
    }

  },

  methods: {
    textFilter(){
      if(this.$i18n.locale == 'es'){
        this.brandsOptions[0].name = 'Todas';
        this.modelsOptions[0].name = 'Todos';
      }
      if(this.$i18n.locale == 'en'){
        this.brandsOptions[0].name = 'All';
        this.modelsOptions[0].name = 'All';
      }
    },

    getCategories: function(){
      axios.get(tools.url('/api/categories')).then((response)=>{
        this.categories = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getProducts: function(){
      axios.get(tools.url('/api/products')).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getProductsCategory: function(category_id, subcategory_id){
      this.isLoading = true;
      let filter_brand = this.filter_brand ? this.filter_brand : null;
      let filter_model = this.filter_model ? this.filter_model : [];
      //this.getCategories();

      axios.post(tools.url('/api/products/category'),{category_id:category_id, subcategory_id:subcategory_id, brand_id: filter_brand, modelo_id: filter_model, filters: this.filters}).then((response) =>{
          this.products = response.data.products;
          this.productsAll = response.data.products;
          this.category = response.data.category;
          this.subcategory = response.data.subcategory;
          this.brandsOptions = response.data.filters.brands;
          this.modelsOptions = response.data.filters.modelos;

          this.tipo = response.data.filters.extras.tipo;
          this.medida = response.data.filters.extras.medida;
          this.color = response.data.filters.extras.color;
          this.cuello = response.data.filters.extras.cuello;
          this.terminacion = response.data.filters.extras.terminacion;

          this.categories = response.data.filters.categories;

          let aux = { id: null, name:'All'}
          this.brandsOptions.unshift(aux);

          let auxModel = { id:null, name:'All'}
          this.modelsOptions.unshift(auxModel);

          this.textFilter();

          this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },

    onSubmitFilters(evt) {
      evt.preventDefault();
    },
  },

  mounted(){
    if(this.$route.params.category && this.$route.params.subcategory){
      this.category_id = this.$root._getURLID(this.$route.params.category);
      this.subcategory_id = this.$root._getURLID(this.$route.params.subcategory);
      this.getProductsCategory(this.category_id, this.subcategory_id);
    }

    if(this.$route.params.category && !this.$route.params.subcategory){
      this.category_id = this.$root._getURLID(this.$route.params.category);
      this.getProductsCategory(this.category_id, null);
    }
  },
}
</script>
