<template lang="html">
  <b-form @submit="onSubmit()">
    <h6>Password</h6>
    <hr class="c-hr" />

    <b-form-group class="custom-input"
      label="Type your current password:"
      label-for="i-password">
      <b-form-input id="i-password" type="password"
        v-model="form.password" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Type your new password:"
      label-for="i-npassword">
      <b-form-input id="i-npassword" type="password"
        v-model="form.npassword" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Type your new password again:"
      label-for="i-conpassword">
      <b-form-input id="i-conpassword" type="password"
        v-model="form.conpassword" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-button type="submit" class="btn-red btn-form" variant="primary">Change password</b-button>

  </b-form>
</template>

<script>
export default {
  data(){
    return{
      form: {
        password: '',
        npassword: '',
        conpassword: ''
      }
    }
  },

  methods: {
    onSubmit(){
      console.log('My password...');
    }
  }
}
</script>
