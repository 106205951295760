<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>{{ $t('productsPage.texts.products1') }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ $t('productsPage.texts.products2') }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>{{ $t('productsPage.texts.showFilters') }}</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>{{ $t('productsPage.texts.filters') }}</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container">
            <!-- Marca -->
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.brand') }}</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="filter_brand"
                  :aria-describedby="ariaDescribedby"
                  name="filter-1">
                  <b-form-radio :value="i.id" v-for="(i, fbrInx) in brandsOptions" :key="'fbrInx-'+fbrInx">{{ i.name }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <!--  -->

          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e">
            <div class="col-sm-6 col-md-4 col-lg-3 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/product/'+$root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="out-of-stock" v-if="product.stock < 1">{{ $t('productsPage.texts.noStock') }}</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                </div>
                <p class="p-button">
                  <a class="btn-more">{{ $t('productsPage.texts.explore') }}</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!products.length">{{ $t('productsPage.texts.noResults') }}</h5>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      products: [],
      productsAll:[],

      filter_brand:'null',
      brandsOptions:[],

      filters: {
        keywords: '',
      },
    }
  },

  methods: {
    getProducts: function(){
      this.isLoading = true;

      axios.get(tools.url('/api/products')).then((response)=>{
        this.products = response.data;
        this.productsAll = response.data;

        // this.brandsOptions = response.data.brands;
        // var aux = {
        //   id:'null',
        //   name:'Filtrar por marca'
        // }
        // this.brandsOptions.unshift(aux);

        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getSearch(){
      this.isLoading = true;

      axios.post(tools.url('/api/search'),this.filters).then((response)=>{
        this.products = response.data;
        this.productsAll = response.data;

        // this.brandsOptions = response.data.brands;
        // var aux = {
        //   id:'null',
        //   name:'Filtrar por marca'
        // }
        // this.brandsOptions.unshift(aux);

        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  mounted(){
    if(this.$route.query.keywords){
      this.filters.keywords = this.$route.query.keywords;
      this.getSearch();
    }else{
      this.getProducts();
    }
  },

  watch: {
    'filter_brand': function(val){
        if (val != 'null') {
            var newproducts = [];
            for (var i = 0; i < this.productsAll.length; i++) {
                if(this.productsAll[i].brand_id == val){
                    newproducts.push(this.productsAll[i]);
                }
            }
            this.products = newproducts;
        }
        else{
            this.products = this.productsAll;
        }
    }
  },
}
</script>
