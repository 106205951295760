<template lang="html">
  <div id="description-page">
    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>{{ $t('productsPage.texts.product') }}</span>
        <span class="line">/</span>
        <span>{{ product.name }}</span>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description">
      <div class="row">

        <div class="col-lg-6 col-gallery">
          <swiper :options="galleryOptions">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <v-zoom :img="row" :width="'100%'"></v-zoom>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <b-form class="col-lg-5 col-description" @submit="addCart">
          <!-- <div class="bar-fav">
            <button type="button" class="btn-fav actived"><i class="icon"></i></button>
          </div> -->

          <h1 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h1>
          <h6 class="sku">{{ $t('productsPage.texts.sku') }}: {{ product.sku }}</h6>

          <!-- <h4 class="price discount"><span>$ {{ product.price }}</span></h4> -->
          <!-- <h4 class="price">$ {{ product.price }}</h4> -->

          <h6 class="mt-2 subtitle">{{ $t('productsPage.texts.description') }}</h6>
          <div class="txt-description" v-html="$i18n.locale == 'es' ? product.description_es  : product.description"></div>

          <h6 class="mt-2 subtitle">{{ $t('productsPage.texts.category') }}</h6>
          <div class="txt-description">{{ $i18n.locale == 'es' ?  product.category_es  : product.category }}</div>

          <h6 class="mt-2 subtitle">{{ $t('productsPage.texts.subcategory') }}</h6>
          <div class="txt-description">{{ $i18n.locale == 'es' ? product.subcategory_es : product.subcategory }}</div>

          <h6 class="mt-2 subtitle">{{ $t('productsPage.texts.brand') }}</h6>
          <div class="txt-description">{{ product.brand }}</div>

          <h6 class="mt-2 subtitle">{{ $t('productsPage.texts.model') }}</h6>
          <div class="txt-description">{{ product.modelo }}</div>

          <!-- <h6 class="mt-2 subtitle">Measures  </h6>
          <div class="txt-description">
              {{ product.alto }} height  <br/>
              {{ product.ancho }} width <br/>
              {{ product.largo }} length  <br/>
              {{ product.peso }} weight  <br/>
              {{ product.diametro }} diameter <br/>
          </div> -->

          <h6 class="mb-1 subtitle">{{ $t('productsPage.texts.quantity') }}</h6>
          <b-form-spinbutton id="sb-inline" v-model="form.quantity" inline></b-form-spinbutton>

          <div class="d-block mt-4 mb-2" >
            <!-- <b-button type="submit" class="my-2 my-xl-0 btn-submit">Add to cart <i class="far fa-shopping-cart"></i></b-button> -->
            <router-link class="my-2 my-xl-0 btn btn-submit" :to="{ name: 'contacto', query: { p: product.name+', SKU: '+product.sku } }">{{ $t('productsPage.texts.askInfo') }}</router-link>
          </div>

          <hr />

          <div class="col-12 col-extra" id="col-extra">
            <div class="row">
              <!-- <div class="col b-print">
                <h6>Imprimir</h6>
                <div class="btn-q" @click="printDiv('product-description')"><span><i class="fas fa-print"></i></span></div>
              </div> -->

              <!-- <div class="col px-0 b-sharing">
                <h6>{{ $t('productsPage.texts.share') }}</h6>

                <ShareNetwork
                  v-for="(network, pdInx) in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{backgroundColor: network.color}"
                  :url="sharing.url"
                  :title="sharing.title"
                  :description="sharing.description"
                  :quote="sharing.quote"
                  :hashtags="sharing.hashtags"
                  :twitterUser="sharing.twitterUser">
                  <div class="btn-q"><span><i :class="network.icon"></i></span></div>
                </ShareNetwork>

              </div> -->
            </div>
          </div>
        </b-form>

        <!-- <div class="col-11 mx-auto col-extrainfo">
          <div class="row row-info">
            <div class="col-lg-6 col-label">
              <h6>Genero</h6>
            </div>
            <div class="col-lg-6 col-txt" v-html="product.gender"></div>
          </div>

          <div class="row row-info" v-if="product.color">
            <div class="col-lg-6 col-label">
              <h6>Color</h6>
            </div>
            <div class="col-lg-6 col-txt" v-html="product.color.name"></div>
          </div>

          <div class="row row-info">
            <div class="col-lg-6 col-label">
              <h6>Características</h6>
            </div>

            <div class="col-lg-6 col-txt" v-html="product.caracteristicas"></div>
          </div>
        </div> -->

      </div>
    </section>

    <section class="container oversized-container related-products-section" id="related-products">
      <div class="row">
        <div class="col-12 px-0 col-s-title">
          <h2 class="title">{{ $t('productsPage.texts.relProducts') }}</h2>
          <hr class="mt-2 mb-2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/product/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">{{ $t('productsPage.texts.noStock') }}</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                  </div>
                  <p class="p-button">
                    <a class="btn-more">{{ $t('productsPage.texts.explore') }}</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import vZoom from 'vue-zoom';
export default {
  components: {vZoom},

  data() {
    return {
      id: null,

      form: {
        size: null,
        quantity: 1,
        color: null
      },

      product: [],
      category: [],
      products: [],

      // == Sharing ==
      sharing: {
        url: 'https://5pgtruckparts.us/#/product/',
        title: '5PG Semi truck accessories',
        description: 'This is a description example',
        quote: 'This is a Quote',
        hashtags: 'this,isthe,hashtag',
        twitterUser: 'twitter'
      },

      networks: [
        { network: 'twitter', name: 'Twitter', icon: 'fab fa-twitter' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fa-facebook-f' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fa-pinterest-p' },
      ],
      // == ==

      colors: [],
      gallery: [],
      relatedProducts: [],


      // == Carrusel options ==
      galleryOptions: {
        loop: false,
        speed: 600,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      productsOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  watch: {
        "$route.params.id" : function(v){
            //this.id = this.$route.params.id;
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getProduct();
        },
    },

  methods:{
    toast() {
      this.$bvToast.toast(`The product was added to your cart`, {
        title: `Product added to cart`,
        toaster: 'b-toaster-bottom-right b-toaster-custom-1',
        variant: 'success',
        solid: true,
        appendToast: true
      })
    },

    getProduct(){
      axios.get(tools.url('/api/products/' + this.id)).then((response)=>{
        this.product = [];
        this.gallery = [];
        this.product = response.data;
        this.gallery = response.data.gallery;
        this.category = response.data.category;
        if(this.product.stock > 0){
          this.form.quantity = 1;
        }
        this.getRelated(this.product.id);
      }).catch((error)=>{
        console.log(error);
      });
    },

    addCart(evt){
      evt.preventDefault();
      var cart = [];
      var encontrado = false;

      if(localStorage.cart){
        cart = JSON.parse(localStorage.getItem('cart'));

        for(var i = 0; i < cart.length ; i++){
          if(cart[i].id == this.id && cart[i].size == this.form.size && cart[i].color == this.form.color){
            var suma = cart[i].quantity + this.form.quantity;
            cart[i].quantity = suma <= this.product.stock ? suma : this.product.stock;
            encontrado = true;
            break;
          }
        }

        if(!encontrado){
          cart.push({ id:this.id, quantity:this.form.quantity, size: this.form.size, color: this.form.color});
        }
      } else{
        cart.push({ id:this.id, quantity:this.form.quantity, size: this.form.size, color: this.form.color });
      }
      localStorage.setItem("cart",JSON.stringify(cart));
      this.$root.cartCount = cart.length;
      this.toast();
    },

    getRelated(id){
      axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

  },

   mounted(){
    //this.id = this.$route.params.id;
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getProduct();
  }
}
</script>
