import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '*', redirect: '/' },
      { path: '/', component: require(page+'home.vue').default }, // 404
      { path: '/about-us', component: require(page+'about-us/index.vue').default, meta:{title:"About us"}},
      { path: '/catalog', component: require(page+'catalog/index.vue').default, meta:{title:"Catalog"}},
      { path: '/faq', component: require(page+'faqs/index.vue').default, meta:{title:"FAQs"}},
      { path: '/how-to-buy', component: require(page+'how-to-buy/index.vue').default, meta:{title:"How to buy?"}},
      { path: '/contact', component: require(page+'contact/index.vue').default, name: 'contacto', meta:{title:"Contact"}},

      { path: '/privacy-notice', component: require(page+'text-page/privacy.vue').default, meta:{title:"Privacy notice"}},
	    { path: '/terms-&-conditions', component: require(page+'text-page/terms.vue').default, meta:{title:"Terms & Conditions"}},

      { path: '/products', component: require(page+'products/results.vue').default, meta:{title:"Products"}},
      // { path: '/novedades', component: require(page+'products/novedades.vue').default, meta:{title:"Novedades"}},
      { path: '/product/:id?', component: require(page+'products/description.vue').default, meta:{title:"Product"}},
    //   { path: '/category/:id?', component: require(page+'products/category.vue').default, meta:{title:"Categoria"}},
      { path: '/category/:category/:subcategory?', component: require(page+'products/category.vue').default, meta:{title:"Category"}},
      { path: '/brand/:id?', component: require(page+'products/brand.vue').default, meta:{title:"Brand"}},
      { path: '/search', component: require(page+'products/results.vue').default, meta:{title:"Search"}},

      // { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},
      // { path: '/historial-de-compras', component: require(page+'carrito/historial.vue').default, meta:{title:"Mi historial de compras"}},
      // { path: '/mis-favoritos', component: require(page+'carrito/mis-favoritos.vue').default, meta:{title:"Mis favoritos"}},

      { path: '/sign-in', component: require(page+'user-access/login.vue').default, meta:{title:"Sign in"}},
      { path: '/reset-password', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Reset password"}},
      { path: '/sign-up', component: require(page+'user-access/register.vue').default, meta:{title:"Sign up"}},

      { path: '/user', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'My info' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'My info' }
  			},
  			{
  				path: 'password',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Change password' }
  			},

  			// {
  			//   path: 'nueva-cotizacion',
  			//   component: require(page+'usuario-cuenta/nueva-cotizacion.vue'),
  			//   meta:{ title: 'Mis cotizaciones' }
  			// },
  			// {
  			//   path: 'mis-cotizaciones',
  			//   component: require(page+'usuario-cuenta/mis-cotizaciones.vue'),
  			//   meta:{ title: 'Mis facturas' }
  			// }
  	  	]
  		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},50);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "5PG Semi Truck Accessories", filter: (title)=>{ return title+" - 5PG Semi Truck Accessories"; } }
);

// export {routes};
export default MyRouter;
