<template lang="html">
  <div id="catalog-page">

    <section class="container oversized-container catalogues-section">
      <div class="row mb-1">
        <div class="col-12 col-s-title">
          <h2 class="title blue">AVAILABLE CATALOGS</h2>
        </div>
      </div>

      <div class="row">
        <!-- Results -->
        <div class="col-12">
          <div class="row sample-products-wrapper xxl-5e">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 sample-product shad-prod" v-for="(catalog, index) in catalogs" :key="index">
              <router-link class="outlined-box" to="">
                <div class="box-image">
                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+catalog.img+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="name">{{ catalog.name }}</h6>
                  <h6 class="brand">{{ catalog.code }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">DOWNLOAD</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!catalogs.length">No catalogs found</h5>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      catalogs: [
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
        { img: 'public/images/pages/catalogs/catalog-1.jpg', name: 'Name of the Catalog', code: 'Code X8S0' },
      ],
    }
  }
}
</script>
