<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>{{ $t('productsPage.texts.brands') }}</span>
        <span class="line">/</span>
        <span>{{ brand.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ brand.name }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>{{ $t('productsPage.texts.showFilters') }}</span>
          </a>
        </div>
        <!--  -->

        <!-- Col filters -->
        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>{{ $t('productsPage.texts.filters') }}</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container">
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> {{ $t('productsPage.texts.model') }}</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group v-model="filter_model" :aria-describedby="ariaDescribedby" name="filtro">
                  <b-form-checkbox :value="i.id" v-for="(i, index) in modelsOptions" :key="index">{{ i.name }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>

          </b-form>
        </div>
        <!--  -->

        <!-- Results -->
        <div class="col-lg">
            <div class="row sample-products-wrapper xxl-4e">
            <div class="col-sm-6 col-md-4 col-lg-3 sample-product shad-prod" v-for="(product, index) in products" :key="index">
                <router-link class="outlined-box" :to="'/producto/'+ $root._converToURL(product.name, product.id)">
                    <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">{{ $t('productsPage.texts.noStock') }}</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                        <img src="public/images/shared/empty.png" >
                    </div>
                    </div>

                    <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                    </div>
                    <p class="p-button">
                    <a class="btn-more">{{ $t('productsPage.texts.explore') }}</a>
                    </p>
                </router-link>
                </div>

                <h5 v-if="!products.length && !isLoading">{{ $t('productsPage.texts.noResults') }}</h5>
            </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,
      products: [],

      brand: [],
      brand_id:null,
      products: [],

      filter_model: [],
      modelsOptions: [],
      productsAll: []
    }
  },

  watch: {
    "$route.params.id" : function(v){
      this.brand_id = this.$root._getURLID(this.$route.params.id);
      if(this.brand_id){
          this.products=[];
          this.getProductsBrand(this.brand_id);
      }
    },

    'filter_model': function(val){
        if(this.brand_id){
          this.products = [];
          this.getProductsBrand(this.brand_id);
        }
    },

    '$i18n.locale': function(newVal, oldVal) {
      this.textFilter();
    }
  },

  methods: {
    textFilter(){
      if(this.$i18n.locale == 'es'){
        this.modelsOptions[0].name = 'Todas los modelos';
      }
      if(this.$i18n.locale == 'en'){
        this.modelsOptions[0].name = 'All models';
      }
    },

    getProductsBrand: function(brand_id){
      this.isLoading = true;

      axios.post(tools.url('/api/products/brand/'+brand_id), {modelo_id: this.filter_model}).then((response) =>{
          this.products = response.data.products;
          this.productsAll = response.data.products;
          this.brand = response.data.brand;
          this.modelsOptions = response.data.modelos;
          this.isLoading = false;

          let auxModel = { id:null, name:'All models'}
          this.modelsOptions.unshift(auxModel);

          this.textFilter();

      }).catch((error)=>{
        console.log(error);
        this.isLoading = false;
      });
    }
  },

  mounted(){
    if(this.$route.params.id){
      this.brand_id = this.$root._getURLID(this.$route.params.id);
      this.getProductsBrand(this.brand_id);
    }
  },
}
</script>
