<template lang="html">
  <div id="text-page">
    <div class="container med-container">
      <div class="col-12 col-s-title">
        <h1 class="title"><span>{{ $t('privacyPage.title') }}</span></h1>
        <h6 class="txt-blue text-center">({{ $t('privacyPage.onlyEnglish') }})</h6>
      </div>

      <p>
        The goal of the website is to present products and  5pg Truck Parts benefits and the same is available for information and interest. The following terms of use, which may be changed periodically, will be apply to all those who access the site, as well as their use and content.
      </p>

      <br />
      <h5>1. Objectives of the information</h5>
      <p>
        The content presented on the site 5pg Truck Parts, only aims the communication. The site does not provide advice or recommendations of any kind and not be used as basis for any decision or action. It is recommended to consult the relevant field qualified professionals regarding the applicability of any aspect of the content.
      </p>

      <br />
      <h5>2. Responsibility</h5>
      <p>
        5pg Truck Parts it is not responsible for any damage caused or your access to the site or the inability to access or reliance on any information provided herein injury. 5pg Truck Parts rejects any liability for direct, indirect, incidental, consecutive or other special opportunities and lost profits or any other loss or damage. This limitation includes damages or viruses that may affect your computer equipment.
      </p>

      <br />
      <h5>3. Links to other web sites</h5>
      <p>
        Any link with another website is presented for the convenience of the user.  5pg Truck Parts it does not have responsibility neither control over the content or operation of such sites and will not be responsible for any damage or injury arising from that content or it is operation.
      </p>

      <br />
      <h5>4. Modifications</h5>
      <p>
        5pg Truck Parts reserves the right to alter, amend, replace or delete any content, restrict access to or discontinue distribution of this site at any time and under own discretion.
      </p>

      <br />
      <h5>5. Intellectual property and use of the content</h5>
      <p>
        The content of this site is owned by  5pg Truck Parts and is protected by intellectual property laws. The trademarks registered and services, as well as logos and products mentioned on this site are legally protected and may not be used either without the prior written consent of 5pg Truck Parts. However, you are welcome to download content, only by personal use, non commercial use. Is not authorized any change or reproduction of the content and this it will not copied or used in any other way.
      </p>

      <br />
      <h5>6. Privacy and data protection</h5>
      <p>
        In strict adherence to the provisions of the Federal Law on Protection of Personal Data Held by Private Parties (hereinafter "the Act"), 5pg Truck Parts makes you as follows:<br />
      </p>
      <ul class="list-alpha">
        <li>Personal and/or sensitive data and/or information you voluntarily provide or have provided is completely confidential and is protected by  5pg Truck Parts, who is responsible for the use and processing of personal data.</li>
        <li>Using the data collected it will be used only to: (I) Providing required services and related activities; (II) Report on new services that are related to the contract; (III) Comply with obligations to our customers; (IV) Assess the quality of service; (V) Identification purposes; (VI)Statistical purposes; and (VII) to eventually contact you in order to share with interesting news about right. </li>
        <li>By virtue of the transfer of data to give us or had been granted previously authorized  5pg Truck Parts to use the information provided for the purposes described above. </li>
        <li>We undertake not to transmit, sell, rent, disclose or use your personal information for third parties without your consent, except for information that is necessary for the purposes which provided it, or it is required by federal authorities or local, in terms of applicable law. </li>
        <li>You have the right to access your personal data we possess and the details of their treatment and to correct them if they are inaccurate or instruct us to cancel them when it considers that prove to be excessive or unnecessary for the purposes for which they were obtained or oppose to treating them for specific purposes. </li>
        <li>To exercise your rights of access, rectification, cancellation or opposition, the holder of personal data and / or sensitive that they are in power 5pg Truck Parts, should send their request to the email sales sales5truckparts@gmail.com or, personally present Address No.000 in Cologne, City, State Country.</li>
        <li>This Privacy Policy may be modified and updated at any time, in which case  5pg Truck Parts, notify the relevant changes through the website www.5pgtruckparts.us</li>
      </ul>

      <br />
      <h5>7. Credits and technology</h5>
      <p>
        Graphic web development, website programming, hypertext links, databases or other multimedia elements were performed by Vitamin Online Solution®. All content provided on this Web site have been prepared using information from 5pg Truck Parts as source external to the company itself, why Vitamin Online Solution ® not liable for the possible lack of updates or inexact information belonging to such external sources.
      </p>

      <br />
      <h5>8. Governing Law</h5>
      <p>
        The present policies and  privacy notice, as well as general management of the law to do  5pg Truck Parts, governed by law and applicable in the United Mexican States, any dispute arising as a result of its application should be ventilated to the competent courts in the city of Guadalajara, Jalisco, Mexico.
      </p>

    </div>
  </div>
</template>

<script>
export default {
}
</script>
