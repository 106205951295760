<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5 text-center col-info">
          <h1 class="mb-2 page-title">{{ $t('contactPage.title') }}</h1>

          <!-- <p>
            Nos gustaría llevarte nuestro muestrario, regalarte un catálogo y platicarte sobre nuestros atractivos precios de mayoreo en persona.
          </p> -->
          <p>
            {{ $t('contactPage.text1') }}
          </p>

          <h5 class="mt-4 subtitle-sm">{{ $t('contactPage.location') }}</h5>
          <p>
            8160 N Interstate 45,<br />
            Service RD, Palmer, TX 75152
          </p>

          <br />
          <h5 class="subtitle-sm">{{ $t('contactPage.phone') }}</h5>
          <p>
            214-930-6221
          </p>

          <br />
          <h5 class="subtitle-sm">{{ $t('contactPage.office') }}</h5>
          <p>
            972-449-3504<br />
            972-449-3538
          </p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit="onSubmit">
            <b-form-group :label="$t('contactPage.form.labName')">
              <b-form-input
                v-model="form.name"
                type="text"
                required
                :placeholder="$t('contactPage.form.plName')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('contactPage.form.labState')">
              <b-form-input
                v-model="form.state"
                type="text"
                required
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('contactPage.form.labEmail')">
              <b-form-input
                v-model="form.email"
                type="email"
                required
                :placeholder="$t('contactPage.form.plEmail')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('contactPage.form.labPhone')">
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="55 5555 5555"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('contactPage.form.labMessage')">
              <b-form-textarea
                v-model="form.msg"
                :placeholder="$t('contactPage.form.plMessage')"
                required
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send btn-s2">{{ $t('contactPage.form.send') }}</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3367.344332814676!2d-96.6623189!3d32.4367169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864ec408ffffffff%3A0x7ec7695716034935!2s5PG%20Truck%20Parts%2FChrome%20Shop%20Supplier!5e0!3m2!1ses!2sca!4v1628794756530!5m2!1ses!2sca" allowfullscreen="" loading="lazy"></iframe>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      titleModal: 'Message sent',

      form: {
        name: null,
        state: null,
        email: null,
        phone: null,
        msg: null
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, this.titleModal);
          this.form = {
            name: null,
            state: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.state, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    },

    setLangVals(){
      if(this.$i18n.locale == 'es'){
        this.titleModal = 'Message sent';

        if(this.$route.query.p){
          this.form.msg = 'Estoy interesado en el producto ' + this.$route.query.p;
        }
      }
      if(this.$i18n.locale == 'en'){
        this.titleModal = 'Mensaje enviado';

        if(this.$route.query.p){
          this.form.msg = 'I am interested in the product ' + this.$route.query.p;
        }
      }
    }
  },

  beforeMount(){
    this.setLangVals();
  },

  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      this.setLangVals();
    }
  },
}
</script>
