<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit="register">

            <h1 class="mb-3">{{ $t('accessPage.signUp') }}</h1>

            <b-form-group>
              <b-form-input type="text" v-model="form.name" required :placeholder="$t('accessPage.name')"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="text" v-model="form.lastname" required :placeholder="$t('accessPage.lastName')"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required :placeholder="$t('accessPage.email')"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.password" required :placeholder="$t('accessPage.password')"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.password_confirmation" required :placeholder="$t('accessPage.passwordCon')"></b-form-input>
            </b-form-group>

            <div class="d-inline-block mb-3">
              <b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                {{ $t('accessPage.accept') }} <router-link target="_blank" to="/terms-&-conditions">{{ $t('accessPage.terms') }}</router-link>
              </b-form-checkbox>
            </div>

            <p class="mb-1">
              <router-link to="/sign-in">{{ $t('accessPage.haveAccount') }}</router-link>
            </p>

            <div class="mb-4 form-errors" v-if="formErrors.length">
              <hr />
              <ul>
                <li class="text-danger" v-for="formError in formErrors">
                  <small>{{ formError }}</small>
                </li>
              </ul>
            </div>

            <b-form-group class="mt-3">
              <b-button ref="submit" type="submit" class="btn-blue" variant="primary">{{ $t('accessPage.signUp') }}</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        password_confirmation: ''
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    register(evt){
      evt.preventDefault();

      this.formErrors = [];

      if(this.form.password.length < 6){
        let errorMsg = (this.$i18n.locale == 'es') ? 'La contraseña debe tener por lo menos 6 caracteres' : 'The password must have at least 6 characters';

        this.formErrors.push(errorMsg);
        this.modal.block = false;
        this.modal.icon = 'error';
        this.modal.msg = errorMsg;
        this.$refs.modal.open();
      } else {
        if(this.form.password !== this.form.password_confirmation){
          let errorMsg = (this.$i18n.locale == 'es') ? 'Los campos contraseña y confirmación de contraseña deben coincidir' : 'The password and password confirmation fields must match';

          this.formErrors.push(errorMsg);
          this.modal.block = false;
          this.modal.icon = 'error';
          this.modal.msg = errorMsg;
            this.$refs.modal.open();
        } else {
           axios.post(tools.url('/api/user/register'),this.form).then((response)=>{
            this.$parent.user = response.data;
            this.$parent.logged = true;

            axios.get(tools.url('/sanctum/csrf-cookie')).then(() => {
                this.$router.push("/user");
            });
          }).catch((error)=>{
              this.handleErrors(error);
          });
        }
      }
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  mounted(){
    if(this.$root.logged){
        this.$router.push("/user");
    }
  },

  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      this.$refs.submit.click();
    }
  },
}
</script>
