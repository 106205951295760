<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section" v-if="showBanners">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <img v-if="banner.device == 'pc' && $i18n.locale == 'en'" :src="banner.imageUrl">
          <img v-if="banner.device == 'pc' && $i18n.locale == 'es'" :src="banner.imageUrl_es">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <img v-if="banner.device == 'movil' && $i18n.locale == 'en'" :src="banner.imageUrl">
          <img v-if="banner.device == 'movil' && $i18n.locale == 'es'" :src="banner.imageUrl_es">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container pb-4 brands-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ $t('homePage.titles.brands') }}</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(brand, brhInx) in brands" :key="'brhInx-'+brhInx">
              <router-link class="trans-250 box-brand" :to="'/brand/'+$root._converToURL(brand.name, brand.id)">
                <div class="inside">
                  <h5>{{ brand.name }}</h5>
                  <!-- <img v-if="brand.img" :src="brand.img">
                  <img v-else src="https://5pgtrucks.online/public/images/shared/empty.png"> -->
                </div>
              </router-link>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ $t('homePage.titles.products') }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products" v-if="showFavs">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, prhInx) in products" :key="'prhInx-'+prhInx">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/product/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">{{ $t('homePage.texts.noStock') }}</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <!-- <i class="p-discount">-10%</i> -->
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6>
                    <h6 class="price">$ {{ product.price }}</h6> -->
                  </div>
                  <p class="p-button">
                    <a class="btn-more">{{ $t('homePage.texts.explore') }}</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="categories-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-12 col-title">
            <h2>{{ $t('homePage.titles.categories') }}</h2>
          </div>

          <div class="col-md-6 col-xl-3 col-info" v-for="(category, chInx) in categories" :key="'hc-'+chInx">
            <router-link class="box-info" v-bind:style="{ backgroundImage: 'url('+category.img+')' }" :to="'/category/'+$root._converToURL(category.name, category.id)">
              <img class="img" src="public/images/shared/empty.png">
              <div class="inside">
                <h5>{{ $i18n.locale == 'es' ? category.name_es  : category.name }}</h5>
              </div>
            </router-link>
          </div>

          <!-- <div class="col-12 mt-2 text-center col-bottom">
            <router-link class="btn btn-show" to="">{{ $t('homePage.texts.showMore') }}</router-link>
          </div> -->
        </div>
      </div>
    </section>

    <section class="container oversized-container pb-4 products-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ $t('homePage.titles.parts') }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products" v-if="showProds">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/product/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">{{ $t('homePage.texts.noStock') }}</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ $i18n.locale == 'es' ? product.name_es  : product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                  </div>
                  <p class="p-button">
                    <a class="btn-more">{{ $t('homePage.texts.explore') }}</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="about-us-section">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-lg-5 mr-lg-4 col-info">
            <h2>{{ $t('homePage.titles.aboutUs') }}</h2>

            <p>
              {{ $t('homePage.texts.about5PG') }}
            </p>
          </div>

          <div class="col-lg-5 col-image">
            <img class="img-fluid" src="public/images/pages/about-us/image-1.jpg">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      showBanners: false,
      showFavs: false,
      showProds: false,

      banners: [],
      products: [],
      categories: [],
      brands: [],

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
      },

      productsOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      brandsOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 22,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 22,
          },
          1: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home'))
          .then(({data}) =>{
            this.banners = data.banners;
            this.products = data.favorites;
            this.categories = data.categories;
            this.brands = data.brands;
        })
        .finally(()=>{
          setTimeout( ()=>{
            this.showBanners = true;
            this.showFavs = true;
            this.showProds = true;
          }, 500);
        });
    }
  },

    mounted(){
      this.getHome();
    }

}
</script>
