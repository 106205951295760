<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <!-- <div class="col-lg-6 col-image">
        </div> -->

        <div class="col-lg-10 text-justify col-info" id="faqs-box">
          <h1 class="mb-4 page-title">{{ $t('faqsPage.title') }}</h1>

          <h5 class="subtitle-sm" id="fs-1">{{ $t('faqsPage.q1') }}</h5>
          <p v-html="$t('faqsPage.a1')"></p>

          <h5 class="mt-3 subtitle-sm" id="fs-2">{{ $t('faqsPage.q2') }}</h5>
          <p v-html="$t('faqsPage.a2')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q3') }}</h5>
          <p v-html="$t('faqsPage.a3')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q4') }}</h5>
          <p v-html="$t('faqsPage.a4')"></p>

          <h5 class="mt-3 subtitle-sm" id="fs-3">{{ $t('faqsPage.q5') }}</h5>
          <p v-html="$t('faqsPage.a5')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q6') }}</h5>
          <p v-html="$t('faqsPage.a6')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q7') }}</h5>
          <p v-html="$t('faqsPage.a7')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q8') }}</h5>
          <p v-html="$t('faqsPage.a8')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q9') }}</h5>
          <p v-html="$t('faqsPage.a9')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q10') }}</h5>
          <p v-html="$t('faqsPage.a10')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q11') }}</h5>
          <p v-html="$t('faqsPage.a11')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q12') }}</h5>
          <p v-html="$t('faqsPage.a12')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q13') }}</h5>
          <p v-html="$t('faqsPage.a13')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q14') }}</h5>
          <p v-html="$t('faqsPage.a14')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q15') }}</h5>
          <p v-html="$t('faqsPage.a15')"></p>

          <h5 class="mt-3 subtitle-sm">{{ $t('faqsPage.q16') }}</h5>
          <p v-html="$t('faqsPage.a16')"></p>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{    }
  }
}
</script>
