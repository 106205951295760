<template lang="html">
  <div class="footer-content">
    <section class="newsletter-section-v1">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-md-6 col-lg-5 col-title">
            <h2>{{ $t("shared.footer.texts.subscribeN") }}</h2>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-5 col-form">
            <b-form @submit="onSubmit">
              <b-form-group>
                <b-form-input
                  v-model="form.email"
                  type="email"
                  required
                  :placeholder="$t('shared.footer.texts.email')"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" class="btn-send">{{ $t("shared.footer.texts.subscribe") }}</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <footer id="footer">
  		<div class="container oversized-container">
  			<div class="row">

  				<div class="col-lg col-footer col-logo">
  					<img src="public/images/shared/logo.svg" alt="5PG">
  				</div>

  				<div class="col-lg col-footer col-networks">
  					<a class="link-net" target="_blank" href="">
  						<i class="fab fa-facebook-square"></i>
  					</a>
  					<a class="link-net" href="">
  						<i class="fab fa-instagram"></i>
  					</a>
  					<a class="link-net" href="">
  						<i class="fab fa-twitter-square"></i>
  					</a>
  				</div>

  				<div class="col-sm-6 col-lg col-footer">
  					<h5 class="title">{{ $t('shared.footer.texts.location') }}</h5>

  					<p>
  						<span v-html="$t('shared.footer.texts.address')"></span>
              <a target="_blank" href="https://goo.gl/maps/ss2SHhFX42Dq4NVc6">{{ $t('shared.footer.texts.seeMap') }}</a>
  					</p>
  				</div>

  				<div class="col-sm-6 col-lg col-footer">
  					<h5 class="title">{{ $t('shared.footer.texts.faqs') }}</h5>

  					<p>
              <!-- <router-link to="/catalog">Catalog</router-link><br /> -->
              <router-link to="/faq#fs-1" v-scroll-to="'#fs-1'">{{ $t('shared.footer.links.order') }}</router-link><br />
              <router-link to="/faq#fs-2" v-scroll-to="'#fs-2'">{{ $t('shared.footer.links.payment') }}</router-link><br />
              <router-link to="/faq#fs-3" v-scroll-to="'#fs-3'">{{ $t('shared.footer.links.shipping') }}</router-link><br />
  					</p>
  				</div>

  				<div class="col-lg col-footer">
  					<h5 class="mt-2 title">{{ $t('shared.footer.texts.help') }}</h5>

            <p>
              <router-link to="/how-to-buy">{{ $t('shared.footer.links.buy') }}</router-link><br />
              <router-link to="/terms-&-conditions">{{ $t('shared.footer.links.terms') }}</router-link><br />
  						<router-link to="/privacy-notice">{{ $t('shared.footer.links.notice') }}</router-link>
  					</p>
  				</div>

  			</div>
  		</div>
    </footer>

  </div>

</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null,
      }
    }
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("Do you want to sign up for the newsletter?");
      if (r == true) {
        axios.post(tools.url("/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Message sent');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    }
  }
}
</script>
