<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit="recoverPassword()">

            <h1 class="mb-3">Reset password</h1>

            <p class="mb-3">
              Type your email. We will send you a message with the steps to recover your password.
            </p>

            <b-form-group>
              <b-form-input type="email"
                            v-model="form.email"
                            required
                            placeholder="Email">
              </b-form-input>
            </b-form-group>

            <p class="mb-3">
              <router-link to="/sign-in">Do you remember your password? Sign in!</router-link>
            </p>

            <b-form-group>
              <b-button type="submit" class="btn-blue" variant="primary">Reset password</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    recoverPassword(){
      console.log('Recuperar contraseña');
    }
  }
}
</script>
