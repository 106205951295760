<template lang="html">
  <div id="about-us-page-v2">

    <section class="about-us-section">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-lg-5 order-1 order-lg-0 col-image">
            <img class="img-fluid" src="public/images/pages/about-us/image-2.jpg">
          </div>

          <div class="col-lg-5 order-0 order-lg-1 mr-lg-4 col-info">
            <h2>{{ $t('aboutUsPage.titles') }}</h2>

            <p>
              {{ $t('aboutUsPage.text') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="video-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-s-title">
            <h2 class="title">{{ $t('aboutUsPage.video') }}</h2>
          </div>

          <div class="col-12 col-video">
            <iframe src="https://www.youtube.com/embed/5c-eI45uFcc?controls=0&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
