<template lang="html">
  <div id="text-page">
    <div class="container med-container">
      <div class="col-12 col-s-title">
        <h1 class="title"><span>{{ $t('termsPage.title') }}</span></h1>
        <h6 class="txt-blue text-center">({{ $t('termsPage.onlyEnglish') }})</h6>
      </div>

      <h5>TERMS OF USE</h5>
      <p>
        By using this site, you accept all terms, conditions, and notices contained or referred to herein. If you do not agree to these Terms of Use, we suggest you do not use this site. We reserve the right to update or review these Terms of Use at any time at our discretion. Please review these Terms of Use periodically to check for changes.
      </p>

      <br />
      <p>
        <strong>5PG Truck Parts</strong> invests a lot into this website, its brand, and its identity. You may not use, copy, reproduce, redevelop, create work derived from, post, distribute, show, claim ownership, authorship or contribution to it, or incorporate any part of this Website, including but not limited to, its configuration, color combination, functions, processes, content, text, code, algorithms or command sequences, into any other website or work of a similar nature, document, or any other published work, for any motive, without express written consent from 5PG Truck Parts. If you are found to be doing any of the above, 5PG Truck Parts reserves the right to seek immediate legal action through a court order, indemnity for damages, and reimbursement of any and all administrative charges, litigation and lawyers, and court costs subject to the limitations of the bylaws and the court rules.
      </p>

      <p>
        <strong>5PG Truck Parts</strong>, its logos, products, and service brands belong to their respective owners.
      </p>

      <br />
      <p>
        All information, methodologies, studies, video files, audio files, software code, photographs, graphics, processes, technologies, and other materials published on this website are owned and operated by 5PG Truck Parts and may be the subject matter of intellectual property rights reserved by 5PG Truck Parts and its affiliates or licensees.  These terms of use do not grant you a license to use, copy, distribute, or transfer these materials. <br />
        <br />
        All materials provided on these websites are owned and operated by 5PG Truck Parts. They are only designed for informational purposes and may be modified by 5PG Truck Parts at any time. 5PG Truck Parts does not take any responsibility for the correctness or completeness of these materials.<br />
        In the event of any legal action, you agree to submit to the jurisdiction of the federal, state, and local courts.
      </p>

      <br />
      <h5>PAYMENT CONDITIONS</h5>
      <h6>1. Payment</h6>
      <p>
         Acceptable methods of payment include Credit Cards, Deposits, or Bank Transfers through the Paypal payment platform.
      </p>

      <br />
      <h6> 2. Damaged, Faulty, or Incorrect Products</h6>
      <p>
        5PG Truck Parts is not responsible for damage caused by the courier service.<br />
        <br />
        All damaged goods must be reported within a period of 24 to 48 hours after receiving your order. If your package is clearly damaged when it arrives at your door, make sure you take photos as this will help speed up the process with the courier service.<br />
        <br />
        All warranty and/or damaged goods claims must be processed through 5PG Truck Parts email sales5truckparts@gmail.com or telephone (469) 773 7438. Please do not contact the product manufacturer directly as they will not be able to help you obtain a new product.<br />
        <br />
        If you receive a product with multiple defects, or if you receive a product you did not order, please contact our customer service team at your convenience. Please do not install the part as this could prevent us from processing your refund.
      </p>

      <br />
      <h5>REFUND POLICY</h5>
      <p>
        If you are unhappy with any of the items you purchased from us, you can request a refund within 30 days following the shipment date.<br />
        <br />
        To obtain a refund, you must fulfill the following conditions:<br />
        <br />
        You will be asked to provide the following information:
      </p>
      <ul>
        <li>Company name</li>
        <li>Contact name and telephone number</li>
        <li>Order delivery date</li>
        <li>Fecha de recepción del pedido</li>
        <li>A copy of the invoice stating the item number and quantity that was erroneously sent</li>
      </ul>
      <p>
        - All returned items must be in their original and/or optimum conditions that guarantee their resale. <br />
        - The products may not have been dropped or scratched and must have all of their installation materials.<br />
        - Shipment costs are paid by the customer.<br />
        - We do not give cash refunds.
      </p>
      <p>
        Once we have received the damaged item, you will be given store credit towards your account’s next invoice period.
      </p>

      <br />
      <h5>PAYMENT METHODS</h5>
      <p>
        Once you have made your purchase, the 5PG Truck team will contact you to verify your preferred product delivery method. There are two delivery methods:
      </p>
      <p>
        1. 1. Pick up from a Branch.<br />
        2. Shipment through an agreed courier service. (Once your order has been placed, our sales team will contact you to coordinate shipment details, courier service, costs, etc.) All shipment costs are paid by the customer.<br />
        3. Once the shipment price and courier service have been agreed, the buyer must pay us the shipment amount for the order to be shipped.
      </p>

      <br />
      <h5>DELIVERY PERIODS</h5>
      <p>
        The delivery period for all orders depends on the courier service. High volume orders may require a longer delivery period. 5PG Truck Parts is not responsible for shipment delays once orders leave our warehouse.
      </p>

      <br />
      <h5>INVOICING ERRORS</h5>
      <p>
        Invoicing errors must be reported within 2 business days from receipt of the invoice. Customers must provide the invoice number, item number, and quantities. Once verified, you will be sent the corrected invoice.<br />
        <br />
        Please remember our opening hours are:<br />
        Monday to Friday from 8:00am to 5:00pm<br />
        Saturday from 8:00am to 1:00pm
      </p>

    </div>
  </div>
</template>

<script>
export default {
}
</script>
